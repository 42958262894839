<template>
  <div class="container-fluid mt-5">
    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <base-button
                v-if="$currentUserCan($permissions.PERM_EDIT_CASH_REGISTER_READINGS)"
                type="primary"
                icon
                size="sm"
                @click="editCashRegisterReading()"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-edit"></i>
                </span>
              </base-button>

              <base-button
                v-if="$currentUserCan($permissions.PERM_DELETE_CASH_REGISTER_READINGS)"
                type="danger"
                icon
                size="sm"
                @click="deleteCashRegisterReading()"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-trash"></i>
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="viewCashRegister(cashRegisterReading.cashRegister.id)"
                v-if="$currentUserCan($permissions.PERM_VIEW_CASH_REGISTERS)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-eye"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("CASH_REGISTER_READINGS.SHOW_CASH_REGISTER") }}
                </span>
              </base-button>

              <base-button
                v-if="cashRegisterReading.status == 'DRAFT'"
                type="warning"
                icon
                :disabled="cashRegisterReading.status !== 'DRAFT' || loading"
                @click="cancelCashRegister"
                class="btn btn-warning"
              >
                <i class="fas fa-spinner fa-spin" v-if="loading" />
                {{ $t("COMMON.CANCEL") }}
              </base-button>

              <base-button
                v-if="cashRegisterReading.status == 'DRAFT'"
                type="success"
                :disabled="cashRegisterReading.status !== 'DRAFT' || loading"
                @click="validateCashRegister"
                class="btn btn-success"
              >
                <i class="fas fa-spinner fa-spin" v-if="loading" />
                {{ $t("COMMON.VALIDATE") }}
              </base-button>
              <base-button
                type="info"
                icon
                size="sm"
                @click="print()"
                :disabled="loading"
                v-if="cashRegisterReading.status == 'VALIDATED'"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-file-pdf"></i>
                  {{ $t("COMMON.PRINT") }}
                </span>
              </base-button>
            </div>
            <div class="col-2 text-right">
              <base-button @click="goBack()" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div class="col-12 justify-content-center justify-content-sm-between flex-wrap">
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <cash-register-reading-view-global
                    :cashRegisterReading="cashRegisterReading"
                    @cashRegisterReadingUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="global" id="3">
                  <span slot="title">
                    <i class="fa fa-list" />
                    {{ $t("COMMON.DETAILS") }}
                  </span>
                  <cash-register-reading-view-payment-items
                    :cashRegisterReading="cashRegisterReading"
                    @cashRegisterReadingUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <cash-register-reading-view-logs
                    :cashRegisterReading="cashRegisterReading"
                  />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import defaultCashRegisterReading from "./defaultCashRegisterReading";
import CashRegisterReadingViewGlobal from "./partials/CashRegisterReadingViewGlobal.vue";
import CashRegisterReadingViewLogs from "./partials/CashRegisterReadingViewLogs.vue";
import CashRegisterReadingViewItems from "./partials/CashRegisterReadingViewItems.vue";
import CashRegisterReadingViewExpenseItems from "./partials/CashRegisterReadingViewExpenseItems.vue";
import CashRegisterReadingViewPaymentItems from "./partials/CashRegisterReadingViewPaymentItems.vue";
import CashRegisterReadingViewInvoiceItems from "./partials/CashRegisterReadingViewInvoiceItems.vue";
import CashRegisterReadingViewDetails from "./partials/CashRegisterReadingViewDetails.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CashRegisterReadingViewGlobal,
    CashRegisterReadingViewLogs,
    CashRegisterReadingViewItems,
    CashRegisterReadingViewExpenseItems,
    CashRegisterReadingViewPaymentItems,
    CashRegisterReadingViewDetails,
    CashRegisterReadingViewInvoiceItems,
  },

  mixins: [],

  data() {
    return {
      loading: false,
      cashRegisterReading: cloneDeep(defaultCashRegisterReading),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("cashRegisterReadings/get", id);
        this.cashRegisterReading = this.$store.getters[
          "cashRegisterReadings/cashRegisterReading"
        ];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editCashRegisterReading(row) {
      this.$router.push({
        name: "Edit CashRegisterReading",
        params: { id: this.cashRegisterReading.id },
      });
    },
    async deleteCashRegisterReading() {
      const confirmation = await swal.fire({
        title: this.$t("CASH_REGISTER_READINGS.DELETE_THIS_CASH_REGISTER_READING"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "cashRegisterReadings/destroy",
            this.cashRegisterReading.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("CASH_REGISTERS.CASH_REGISTER_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    viewCashRegister(cashRegisterId) {
      this.$router.push({
        name: "View CashRegister",
        params: { id: cashRegisterId },
      });
    },
    async validateCashRegister() {
      const confirmation = await swal.fire({
        title:
          this.cashRegisterReading.amount_gap != 0
            ? this.$t("CASH_REGISTER_READINGS.GAPS_WHEN_VALIDATE", {
                gap: this.$formatCurrency(this.cashRegisterReading.amount_gap),
              })
            : this.$t("CASH_REGISTER_READINGS.VALIDATE_CASH_REGISTER_READING_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch(
          "cashRegisterReadings/validate",
          this.cashRegisterReading.id
        );
        await this.get();
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async cancelCashRegister() {
      const confirmation = await swal.fire({
        title:
          this.cashRegisterReading.amount_gap != 0
            ? this.$t("CASH_REGISTER_READINGS.GAPS_WHEN_CANCEL", {
                gap: this.$formatCurrency(this.cashRegisterReading.amount_gap),
              })
            : this.$t("CASH_REGISTER_READINGS.CANCEL_CASH_REGISTER_READING_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch(
          "cashRegisterReadings/cancel",
          this.cashRegisterReading.id
        );
        await this.get();
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "cashRegisterReadings/print",
          this.cashRegisterReading.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
    goBack() {
      this.$router.push({ name: "List CashRegisterReadings" });
    },
  },
};
</script>
